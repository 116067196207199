//import logo from './logo.svg';
import './App.css';
import frontpicture from "./PA_DL_Front.jpeg";
import backpicture from "./PA_DL_Back.jpeg";


// import IDMetricsCaptureFramework from './IDMetricsCaptureFramework.js';

// isOpenCvLoaded = false;
var capture;
var DLFrontSettings, DLBackSettings, passportSettings, selfieSettings, farSelfieSettings = '',
  capture;
var isSelfieCaptureStart = false;
var autoMode = false;

var outOfFocus = 'Captured image is out of focus. Please retry.';
var outOfGlare = 'Captured image seems to have more glare. Please retry.';
var noFcaesDetected = 'Face not detected in a captured image. Please retry.';

var FrontCaptureResult;
var BackCaptureResult;

var userName = "nerdherd.parveza@eascendant.cloud";
var passWord = "$~+Qp(wqo#FJ3m=z16";

var ACKey = "I!pE@LHaZmRIKBQ1T4Faove";
var SecTocken = "ze#Mk6jvJZ5vXCSF8W@VIKN";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={frontpicture} />
        <img src={backpicture} />

        <button onClick={GetWebSDKVersion}>Check Version</button>
        <button onClick={FrontCapture}>Capture Front</button>
        <button onClick={BackCapture}>Capture Back</button>
        <button onClick={VerifiAPICall}>Verify</button>
      </header>
    </div>
  );
}

function GetWebSDKVersion () {
  window.GetSDKVersion(function(sdkVersion) {
    console.log("Version: " + sdkVersion);
  });
};

function FrontCapture () {

  FrontCaptureResult = new window.IDMetricsCaptureFramework.CaptureResult();

  FrontCaptureResult.setOnAborted(function(e) {
    console.log("setOnAborted function called!");

    if (e.errorType == "camera-permision") {
      console.log(e);
    }
  });

  FrontCaptureResult.setOnEvent(function(erorCode, errorDesc) {
    console.log("setOnEvent function called!");

    if (errorDesc == "bad focus") {

    } else if (errorDesc == "bad glare") {

    } else if (errorDesc == "no faces found") {

    }

  });

  FrontCaptureResult.setOnFinish(function() {
    console.log("setOnFinish function called!");
    alert("sucess!");
  });
  DLFrontSettings.captureMode = autoMode ? "Auto" : "Manual";
  // debugger;
  // window.deviceThreshold.useNativeCamera = false;

  window.IDMetricsCaptureFramework.scanDocument(DLFrontSettings, FrontCaptureResult);
}

function BackCapture () {

  BackCaptureResult = new window.IDMetricsCaptureFramework.CaptureResult();

  BackCaptureResult.setOnAborted(function(e) {
    console.log("setOnAborted function called!");

    if (e.errorType == "camera-permision") {
      console.log(e);
    }
  });

  BackCaptureResult.setOnEvent(function(erorCode, errorDesc) {
    console.log("setOnEvent function called!");

    if (errorDesc == "bad focus") {

    } else if (errorDesc == "bad glare") {

    } else if (errorDesc == "no faces found") {

    }

  });

  BackCaptureResult.setOnFinish(function() {
    console.log("setOnFinish function called!");
    alert("sucess!");
  });
  //DLBackSettings.captureMode = autoMode ? "Auto" : "Manual";

  // debugger;
  // window.deviceThreshold.useNativeCamera=false;
  window.IDMetricsCaptureFramework.scanDocument(DLBackSettings, BackCaptureResult);
}

function VerifiAPICall() {
  //alert("made api call");
  
  fetch(
    "https://api.amalgam-east.ascendant.cloud/AirApi/2.0/AuthenticateDocument", 
    {
      mode: 'no-cors',
      method: "POST",
      headers: {
        //username: {userName},
        //password: {passWord}
        AccountAccessKey: {ACKey},
        SecretToken: {SecTocken}
        //Location: "1111",
        //DocumentType: "License",
      },
      body: {
        Front: {frontpicture},
        Back: {backpicture}
        //Front: FrontCaptureResult.result,
        //Back: BackCaptureResult.result
      },
    }
  ).then((response) => response.json());
  
}

function UseNative () {
  window.deviceThreshold.useNativeCamera = false;
}

window.onCaptureFrameworkLoaded = function() {
  console.log("AID webSDK LOADED Successfully!");
  window.deviceThreshold.useNativeCamera = false;

  /* Initiate DLFrontSetting*/
  DLFrontSettings = new window.IDMetricsCaptureFramework.DocumentSettings();
  /*Setting for DL back*/
  DLFrontSettings.documentType = "License";
  DLFrontSettings.documentSide = "Front";
  DLFrontSettings.captureMode = "Auto";
  DLFrontSettings.frontFocusThreshold = navigator.userAgent.search(/Edge/g) >= 0 ? 10 : 40;
  DLFrontSettings.frontGlareThreshold = 12.5;
  DLFrontSettings.compressionType = "JPEG";
  DLFrontSettings.compressionQuality = 50;
  DLFrontSettings.frontCaptureAttempts = 4;
  DLFrontSettings.overlayText = "Align ID and Tap Screen<br/> to Capture.";
  DLFrontSettings.overlayTextAuto = "Align ID within box and Hold";
  DLFrontSettings.overlayColor = "yellow";
  DLFrontSettings.enableFaceDetection = true;
  DLFrontSettings.setManualTimeout = 8;

  /* Initiate DLBackSetting*/
  DLBackSettings = new window.IDMetricsCaptureFramework.DocumentSettings();
  /*Setting for DL back*/
  DLBackSettings.documentType = "License";
  DLBackSettings.documentSide = "Back";
  DLBackSettings.captureMode = "Manual";
  DLBackSettings.backFocusThreshold = navigator.userAgent.search(/Edge/g) >= 0 ? 10 : 50;
  DLBackSettings.backGlareThreshold = 20.5;
  DLBackSettings.compressionType = "JPEG";
  DLBackSettings.compressionQuality = 50;
  DLBackSettings.backCaptureAttempts = 4;
  DLBackSettings.overlayText = "Align ID and Tap Screen<br/> to Capture.";
  DLBackSettings.overlayTextAuto = "Align ID within box and Hold";
  DLBackSettings.overlayColor = "yellow";
  DLBackSettings.setManualTimeout = 8;
  DLBackSettings.isBarcodeDetectedEnabled = true;

}

export default App;
