import $ from "jquery-slim";

window.$ = $;
window.jQuery = $;

/*
window.onCaptureFrameworkLoaded = function () {
  console.log("AID webSDK LOADED Successfully!");
  window.IDMetricsCaptureFramework.GetSDKVersion(function(sdkVersion) {
    console.log("Version: " + sdkVersion);
  });
};
*/

fetch("IDMetricsCaptureFramework.js").then((response) => {
  return response.text().then((text) => {
    eval.apply(window, [text]);
  });
});